/**
 * impressum.jsx
 *
 * @file Impressum page of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { GatsbySeo } from 'gatsby-plugin-next-seo'
import MuiLink from '@mui/material/Link'
import NoSsr from '@mui/material/NoSsr'
import React from 'react'
import Typography from '@mui/material/Typography'
import { useLocation } from '@reach/router'

// internal imports
import { Link } from '../components/Link'
import { Obfuscate } from '../components/Obfuscate'
import routes from '../data/routes.json'

/**
 * This component represents the Impressum page of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const ImpressumPage = () => {

	const location = useLocation()

	return (
		<>
			<GatsbySeo title="Impressum" />
			<Typography align="center" color="primary" gutterBottom variant="h4">Impressum</Typography>
			<Typography id="m46" variant="h6">Diensteanbieter</Typography>
			<Typography paragraph variant="body2">
				Jugend debattiert NRW<br />
				(Landeskoordination: Kerstin Hauke und Stephan Lange-Weber)<br />
				Marktstraße 51-55<br />
				46045 Oberhausen<br />
				Deutschland<br />
			</Typography>
			<Typography id="m56" variant="subtitle1">Kontaktmöglichkeiten</Typography>
			<Typography paragraph variant="body2">
				<strong>E-Mail-Adresse</strong>: <NoSsr><MuiLink component="span" underline="hover"><Obfuscate email={ process.env.GATSBY_SITE_MAIL } /></MuiLink></NoSsr><br />
				<strong>Kontaktformular</strong>: <Link to={ routes[ 4 ].path }>{ `${ location.origin }${ routes[ 4 ].path }` }</Link>
			</Typography>
			<Typography id="m153" variant="subtitle1">Vertretungsberechtigte</Typography>
			<Typography paragraph variant="body2">
				<strong>Vertretungsberechtigt</strong>: Kerstin Hauke (Landeskoordinatorin)<br />
				<strong>Vertretungsberechtigt</strong>: Stephan Lange-Weber (Landeskoordinator)<br />
				<strong>Vertretungsberechtigt</strong>: Sebastian Berg (stellv. Landeskoordinator)<br />
			</Typography>
			<Typography id="m174" variant="subtitle1">Online-Streitbeilegung (OS)</Typography>
			<Typography paragraph variant="body2">
				Online-Streitbeilegung: Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <MuiLink
				href="https://ec.europa.eu/consumers/odr/"
				target="_blank"
				underline="hover">https://ec.europa.eu/consumers/odr/</MuiLink> finden. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung ihrer Streitigkeiten zu nutzen.
			</Typography>
			<Typography id="m65" variant="subtitle1">Haftungs- und Urheberrechtshinweise</Typography>
			<Typography paragraph variant="body2">
				<strong>Haftungsausschluss</strong>: Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende Informationen (z.B. das Impressum, die Datenschutzerklärung, AGB oder Widerrufsbelehrungen für Verbraucher) handelt. Wir behalten uns vor, die Inhalte vollständig oder teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind freibleibend und unverbindlich.
			</Typography>
			<Typography paragraph variant="body2">
				<strong>Links auf fremde Webseiten</strong>: Inhalte fremder Webseiten, auf die wir direkt oder indirekt verweisen, liegen außerhalb unseres Verantwortungsbereiches und machen wir uns nicht zu Eigen. Für alle Inhalte und insbesondere für Schäden, die aus der Nutzung der in den verlinkten Webseiten aufrufbaren Informationen entstehen, haftet allein der Anbieter der verlinkten Webseiten.
			</Typography>
			<Typography paragraph variant="body2">
				<strong>Urheberrechte und Markenrechte</strong>: Alle auf dieser Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteverwalter.
			</Typography>
			<Typography paragraph variant="body2">
				<strong>Hinweise auf Rechtsverstöße</strong>: Sollten Sie innerhalb unseres Internetauftritts Rechtsverstöße bemerken, bitten wir Sie uns auf diese hinzuweisen. Wir werden rechtswidrige Inhalte und Links nach Kenntnisnahme unverzüglich entfernen.
			</Typography>
			<Typography className="seal" paragraph variant="body2">
				<MuiLink
					href="https://datenschutz-generator.de/?l=de"
					title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
					target="_blank"
					underline="hover">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</MuiLink>
			</Typography>
			<Typography sx={{ my: 1 }} variant="body2">
				Diese Website wird entwickelt von <MuiLink href="https://robinwalter.me/" target="_blank" underline="hover">Robin Walter</MuiLink>. Für die Gestaltung wird das <MuiLink href="https://mui.com/" rel="noopener noreferrer" target="_blank" underline="hover">@mui/material</MuiLink> Framework verwendet.
			</Typography>
		</>
	)
}

export default ImpressumPage
